import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {Doc} from '../models';

@Injectable({
  providedIn: 'root'
})
export class EventCenterService {

  // SideNav State
  sideNavOpened = new Subject<boolean>();

  // Open or Close SideNav
  openSideNav = new Subject<boolean>();

  // Global Event for Loading Screen
  showLoading = new Subject<boolean>();

  // show loader for my activity tabs
  activityTabOnLoad = new Subject<boolean>();

  // show loader for my activity tabs
  contractUpdated = new Subject<Doc>();
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {DisplayTemplateComponent} from "./modules/shared/components/download-manager/download-manager.component";
import {DateAdapter} from "@angular/material/core";
import {EventCenterService} from "./modules/shared/services/event-center.service";
import {DataCenterServiceService} from "./modules/shared/services/data-center-service.service";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Doc, User} from "./modules/shared/models";
import {WebSocketService} from "./modules/shared/services/web-socket.service";
import {Subscription} from "rxjs";

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports: [RouterOutlet, DisplayTemplateComponent,],
})
export class AppComponent implements OnInit, OnDestroy
{
    subscriptions: Subscription[] = [];

    /**
     * Constructor
     */
    constructor(
        private eventCenterService: EventCenterService,
        private dataCenterService: DataCenterServiceService,
        private webSocketService: WebSocketService,
    ) {
    }

    ngOnInit() {
       // this.initWebSocket()
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
    }

    initWebSocket(): void {
        this.dataCenterService.currentUser.subscribe((user) => {
            if (user) {
                this.webSocketService.initRooms([user.company._id]);
                    this.webSocketService
                        .getMessageFrom('contractUpdated')
                        .subscribe((event: Doc) => {
                            console.log('msg contractUpdated received', event);
                            this.eventCenterService.contractUpdated.next(event);
                        })
            }
        })


    }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {Address, Company, ContractType, DataPaginated, Doc, Phone, ResidencePermit, Template} from '../models';
import {environment} from 'environments/environment';
import {SocialNumber} from '../models/social-number';
import {RsqlParams} from "../models/rsql-params";
import {RsqlParamsMapper} from "../mappers/rsql-params-mapper";

export interface MandatoryPropertiesInterface {
  label: string;
  value: string;
}
export interface FieldsToDisplayInterface {
  [key: string]: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class ContractTypeService {

  public availableSubContract: string[] = [
    'CDD pour activité saisonnière liée à la saison touristique',
    'CDD pour remplacer un salarié absent',
    'accroissement saisonnier lié aux vacances scolaires',
    'accroissement temporaire d\'activité',
  ];

  public mandatoryPropertiesList: MandatoryPropertiesInterface[] = [
    // company info
    {label: 'companies.name', value: 'currentRestaurant.company.name'},
    {label: 'address.addressLine1', value: 'currentRestaurant.company.addresses[0].addressLine1'},
    {label: 'address.postalCode', value: 'currentRestaurant.company.addresses[0].postalCode'},
    {label: 'address.city', value: 'currentRestaurant.company.addresses[0].city'},
    {label: 'companies.owner', value: 'currentRestaurant.company.owner'},
    { label: 'companies.naf', value: 'currentRestaurant.company.naf' },
    { label: 'companies.ursaff', value: 'currentRestaurant.company.ursaff' },
    { label: 'companies.siren', value: 'currentRestaurant.company.siren' },

    // employe
    { label: 'employe.firstName', value: 'firstName' },
    { label: 'employe.lastName', value: 'lastName' },
    { label: 'employe.birthDate', value: 'birthDate' },
    { label: 'employe.birthTown', value: 'birthTown' },
    { label: 'employe.birthPostalCode', value: 'birthPostalCode' },
    { label: 'employe.socialNumber', value: 'socialNumber' },
    { label: 'address.addressLine1', value: 'addresses[0].addressLine1' },
    { label: 'address.postalCode', value: 'addresses[0].postalCode' },
    { label: 'address.city', value: 'addresses[0].city' },
    { label: 'nationality', value: 'nationality' },

    // doc
    { label: 'employe.titleEmploye', value: `titleEmploye` },
    { label: 'employe.categorieEmploye', value: `categorieEmploye` },
    { label: 'employe.level', value: `level` },
    { label: 'employe.delayOfRuptur', value: `delayOfRuptur` },
    { label: 'employe.echelon', value: `echelon` },
    { label: 'employe.contractStartDate', value: `contractStartDate` },
    { label: 'employe.categorieEmploye', value: `categorieEmploye` },
    { label: 'employe.dateOfSend', value: `dateOfSend` },
    { label: 'employe.periodEssai', value: `periodEssai` },
    { label: 'employe.tauxHoraire', value: `tauxHoraire` },
    { label: 'employe.salary', value: `salary` },
    { label: 'employe.planification', value: `planification` },
    { label: 'employe.repartitionDureeTravail', value: `repartitionDureeTravail` },
    { label: 'employe.prolongementFromDate', value: `prolongementFromDate` },
    { label: 'employe.prolongementToDate', value: `prolongementToDate` },
    { label: 'employe.prolongementTimeInDays', value: `prolongementTimeInDays` },
    { label: 'employe.objectConvocation', value: `objectConvocation` },
    { label: 'employe.motifAvenant', value: `motifAvenant` },
    { label: 'employe.motifAvenant2', value: `motifAvenant2` },
    { label: 'employe.motifAvenant3', value: `motifAvenant3` },
  ];

  public displayFields: string[] = [
    'employe.currentRestaurant',
    'doc.contractStartDate',
    'doc.dateOfSend',
    'doc.objectConvocation',
    'doc.contractToDate',
    'doc.contractFromDate',
    'doc.planification',
    'doc.repartitionDureeTravail',
    'doc.titleEmploye',
    'doc.delayOfRuptur',
    'doc.categorieEmploye',
    'doc.level',
    'doc.echelon',
    'doc.periodEssai',
    'doc.tauxHoraire',
    'doc.hourConvocation',
    'doc.lieuConvocation',
    'doc.dateConvocation',
    'doc.motifAvenant',
    'doc.motifAvenant2',
    'doc.motifAvenant3',
    'doc.nbrHour',
    'doc.salary',
  ];

  public dayOfWeekPossibilities: string[][] =
    [
      [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    ]

  public availableContracts: string[] = [
    'cdi',
    'cdd',
    'cddUnMois',
    'renouvellementCdd',
    'avenantFormateur',
    'avenantManager',
    'avenantHeureDureeDeterminee',
    'avenantHeureDureeIndeterminee',
    'avenantPlageDureeDeterminee',
    'avenantPlageDureeIndeterminee',
    'avenantChangementPlageDureeDetermineeNuit',
    'avenantChangementPlageDureeIndetermineeNuit',
    'avenantResponsableDeZone',
    'convocationSimple',
    'attestation',
    'attestationManuelle',
    'convocationSanction',
    'convocationDp',
    'convocationPrealableALicenciement',
    'convocationPrealableALicenciementMAP',
    'finPeriodeEssai',
    'finPeriodeEssaiML',
    'annexes',
    'sanctionRappelAOrdre',
    'sanctionLicenciement',
    'sanctionAvertissement',
  'sanctionMiseAPied1Jour',
    'sanctionMiseAPied2Jour',
    'sanctionMiseAPied3Jour',
    'convocationVM',
    'stcAttestation',
    'stcBulletinPortabiliteMutuelle',
    'stcBulletinPortabilitePrevoyance',
    'stcNoticePortabiliteMutuelle',
    'stcNoticePortabilitePrevoyance',
];

  public titleEmploye: string[] = [
    'Equipier(e) polyvalent(e)',
    'Formateur(trice)',
    'Responsable de zone',
    'Responsable opérationnel(le)',
    'Responsable opérationnel(le) confirmé(e)',
    'Assistant(e) direction confirmé(e)',
    'Assistant(e) Direction',
    'Directeur(trice) adjointe(e)',
    'Directeur(trice) adjointe(e) confirmé(e)',
    'Directeur(trice)',
    'Directeur(trice) confirmé(e)',
    'Superviseur',
    'Assistant stagiaire',
    'Responsable opérationnel(le) stagiaire',
  ];

  public titleEmployeManager: string[] = [
    'Formateur(trice)',
    'Responsable de zone',
    'Responsable opérationnel(le)',
    'Responsable opérationnel(le) stagiaire',
  ];

  public categorieEmploye: string[] = [
    'Employé',
    'Cadre',
    'Agent de maîtrise'
  ];

  public level: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public echelon: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];

  public tauxHoraire = 10.15;

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Get all companies available
   * @param active string
   * @param direction string
   * @param pageIndex number
   * @param pageSize number
   * @param filter string
   * @return an array of Companies
   */
  getAll(
    rsql: RsqlParams
  ): Observable<DataPaginated<ContractType>> {

    const m = new RsqlParamsMapper(rsql)

    return this.http.get<DataPaginated<ContractType>>(`${environment.baseUrl}${environment.service.contractTypes.uri}`, { params: m.getHttpParams()});
  }


  /**
   * Create a empty company
   * @return an id of Company
   */
  createEmptyContractType(): Observable<ContractType> {
    return this.http.post<ContractType>(`${environment.baseUrl}${environment.service.contractTypes.uri}`, null);
  }


  /**
   * Get a company by id
   * @return Company
   */
  getContractTypeById(companyId: string): Observable<ContractType> {
    return this.http.get<ContractType>(`${environment.baseUrl}${environment.service.contractTypes.uri}/${companyId}`);
  }

  /**
   * Update a company by id
   * @return Company
   */
  updateContractTypeById(company: ContractType): Observable<ContractType> {
    return this.http.put<ContractType>(`${environment.baseUrl}${environment.service.contractTypes.uri}/${company._id}`, company);
  }

  /**
   * Delete a company by id
   */
  deleteContractTypeById(company: ContractType): Observable<ContractType> {
    return this.http.delete<ContractType>(`${environment.baseUrl}${environment.service.contractTypes.uri}/${company._id}`);
  }
  /**
   * Get status availabled for a company
   */
  getStatus(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.baseUrl}${environment.service.contractTypes.uri}/status`);
  }

  /**
   * Generate Request
   */
  generate(employeId: string, docId: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}${environment.service.contractTypes.uri}/generate/${employeId}/${docId}`);
  }

}

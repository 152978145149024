import { Injectable } from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {User} from '../models';
import {Navigation} from "../../../core/navigation/navigation.types";

@Injectable({
  providedIn: 'root'
})
export class DataCenterServiceService {

  // current connected User
  currentUser = new BehaviorSubject<User>(null);
  // new file to download
  downloadSubject = new BehaviorSubject<string>(null);

  navigation = new BehaviorSubject<Navigation>(null);

}

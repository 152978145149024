/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },
    {
        id   : 'employe',
        title: 'Employe',
        type : 'basic',
        icon : 'heroicons_outline:identification',
        link : '/employe'
    },
    {
        id      : 'admin',
        title   : 'Admin',
        subtitle: 'Administration',
        type    : 'collapsable',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'admin.company',
                title: 'Société',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/admin/company',
            },
            {
                id   : 'admin.restaurant',
                title: 'Restaurants',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/admin/restaurant',
            },
            {
                id   : 'admin.contractType',
                title: 'Type de contrats',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/admin/contract-type',
            },
            {
                id   : 'admin.user',
                title: 'Utilisateur',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/admin/user',
            },
        ],
    },

];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'admin',
        title   : 'Admin',
        subtitle: 'Administration',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'admin.company',
                title: 'Société',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/admin/company',
            },
            {
                id   : 'admin.restaurant',
                title: 'Restaurants',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/admin/restaurant',
            },
            {
                id   : 'admin.contractType',
                title: 'Type de contrats',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/admin/contract-type',
            },
            {
                id   : 'admin.user',
                title: 'Utilisateur',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/admin/user',
            },
        ],
    },

];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import {forkJoin, Observable, of, ReplaySubject, tap} from 'rxjs';
import {ContractType, DataPaginated, User} from "../../modules/shared/models";
import {RsqlParams} from "../../modules/shared/models/rsql-params";
import {ContractTypeService} from "../../modules/shared/services/contract-type.service";
import {TranslocoService} from "@ngneat/transloco";
import {DataCenterServiceService} from "../../modules/shared/services/data-center-service.service";
import {FuseNavigationItem} from "../../../@fuse/components/navigation";
import {defaultNavigation} from "../../mock-api/common/navigation/data";
import { Observer } from 'rxjs'
import {log} from "@angular-devkit/build-angular/src/builders/ssr-dev-server";
@Injectable({providedIn: 'root'})
export class NavigationService
{
    private _httpClient = inject(HttpClient);
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private dataCenterServiceService = inject(DataCenterServiceService)
    private contractTypeService = inject(ContractTypeService)
    private translocoService = inject( TranslocoService)
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {

        return new Observable<Navigation>((obs: Observer<Navigation>) => {
            const nav = this.dataCenterServiceService.navigation.getValue();
            const user: User = this.dataCenterServiceService.currentUser.getValue();
            if (nav) {
                this._navigation.next(nav);
                obs.next(nav)
                obs.complete();
            } else {

               forkJoin([
                    this._httpClient.get<Navigation>('api/common/navigation'),
                    this.getDocs(),
                ]).subscribe(
                    (n: any[]) => {
                        const navigation: Navigation = n[0] as Navigation;
                        const navPerso: FuseNavigationItem = n[1];

                        navigation.default = navigation.default.concat(navPerso);
                        console.log(user)

                        switch (user.roles[0].name) {
                            case "director":
                            case "superviseur":
                                navigation.default = navigation.default.filter(d => ['dashboard', 'employe' , 'embauche' , 'avenant', 'convocation', 'finPeriodeEssai', 'attestation' ].includes( d.id))
                                break;
                            case 'franchise':
                                navigation.default = navigation.default.filter(d => ['dashboard', 'employe'].includes( d.id))
                                break;
                            case 'rh':
                                navigation.default = navigation.default.filter(d => !['admin'].includes(d.id))
                                break;

                                default:
                                    console.log('log ad admin')

                        }

                        this.dataCenterServiceService.navigation.next(navigation);
                        this._navigation.next(navigation);
                        obs.next(navigation)

                        obs.complete();


                    }
                )
            }

        });
    }

    async getDocs(): Promise<FuseNavigationItem[]> {
        const nav: FuseNavigationItem[] = [];
        const [
            embauches,
            avenants,
            convocation,
            finPeriodeEssai,
            attestation,
            sanction,
            stc
        ] = await Promise.all([
            this.getEmploye(),
            this.getAvenant(),
            this.getConvocation(),
            this.getFinPeriodeEssai(),
            this.getAttestation(),
            this.getSanction(),
            this.getStc(),
        ])

        nav.push({
            id: 'embauche',
            title: 'Embauche',
            subtitle: 'Liste des Embauches',
            type: 'collapsable',
            icon: 'heroicons_outline:user',
            children: embauches.data.map((d) => {
                return {
                    id: 'embauche.' + d.name,
                    title: this.translocoService.translate(d.name),
                    type: 'basic',
                    icon: 'heroicons_outline:user',
                    link: '/doc/list/' + d.name,
                }
            })
        })
        nav.push({
            id: 'avenant',
            title: 'Avenants',
            subtitle: 'Liste des Avenants',
            type: 'collapsable',
            icon: 'heroicons_outline:document-text',
            children: avenants.data.map((d) => {
                return {
                    id: 'avenant.' + d.name,
                    title: this.translocoService.translate(d.name),
                    type: 'basic',
                    icon: 'heroicons_outline:document-text',
                    link: '/doc/list/' + d.name,
                }
            })
        })
        nav.push({
            id: 'convocation',
            title: 'Convocations',
            subtitle: 'Liste des Convocations',
            type: 'collapsable',
            icon: 'heroicons_outline:bell-alert',
            children: convocation.data.map((d) => {
                return {
                    id: 'convocation.' + d.name,
                    title: this.translocoService.translate(d.name),
                    type: 'basic',
                    icon: 'heroicons_outline:bell-alert',
                    link: '/doc/list/' + d.name,
                }
            })
        })
        nav.push({
            id: 'finPeriodeEssai',
            title: 'Fin de periode d\'essai',
            subtitle: 'Liste des fin de periodes d\'essais',
            type: 'collapsable',
            icon: 'heroicons_outline:calendar',
            children: finPeriodeEssai.data.map((d) => {
                return {
                    id: 'finPeriodeEssai.' + d.name,
                    title: this.translocoService.translate(d.name),
                    type: 'basic',
                    icon: 'heroicons_outline:calendar',
                    link: '/doc/list/' + d.name,
                }
            })
        })
        nav.push({
            id: 'attestation',
            title: 'Attestation',
            subtitle: 'Liste des attestations',
            type: 'collapsable',
            icon: 'heroicons_outline:document-check',
            children: attestation.data.map((d) => {
                return {
                    id: 'attestation.' + d.name,
                    title: this.translocoService.translate(d.name),
                    type: 'basic',
                    icon: 'heroicons_outline:document-check',
                    link: '/doc/list/' + d.name,
                }
            })
        })
        nav.push({
            id: 'sanction',
            title: 'Sanctions',
            subtitle: 'Liste des sanctions',
            type: 'collapsable',
            icon: 'heroicons_outline:shield-exclamation',
            children: sanction.data.map((d) => {
                return {
                    id: 'sanction.' + d.name,
                    title: this.translocoService.translate(d.name),
                    type: 'basic',
                    icon: 'heroicons_outline:shield-exclamation',
                    link: '/doc/list/' + d.name,
                }
            })
        })
        nav.push({
            id: 'stc',
            title: 'STC',
            subtitle: 'Liste des stc',
            type: 'collapsable',
            icon: 'heroicons_outline:check-badge',
            children: stc.data.map((d) => {
                return {
                    id: 'stc.' + d.name,
                    title: this.translocoService.translate(d.name),
                    type: 'basic',
                    icon: 'heroicons_outline:check-badge',
                    link: '/doc/list/' + d.name,
                }
            })
        })

        return nav;
    }

    async getEmploye(): Promise<DataPaginated<ContractType>> {
        return this.contractTypeService.getAll(
            {
                page: 0, pageSize: 100, q: [
                    {key: 'status', value: 'ENABLED', operator: '=='},
                    {key: 'name', value: 'cdd', operator: '==', condition: ','},
                    {key: 'name', value: 'cdi', operator: '==', condition: ','},
                    {key: 'name', value: 'renouvellement-cdd', operator: '==', condition: ','},
                    {key: 'name', value: 'cddUnMois', operator: '==', condition: ','}
                ]
            } as RsqlParams).toPromise();
    }

    async getAvenant(): Promise<DataPaginated<ContractType>> {
        return this.contractTypeService.getAll(
            {
                page: 0, pageSize: 100, q: [
                    {key: 'status', value: 'ENABLED', operator: '=='},
                    {key: 'name', value: 'avenantHeureDureeDeterminee', operator: '==', condition: ','},
                    {key: 'name', value: 'avenantHeureDureeIndeterminee', operator: '==', condition: ','},
                    {key: 'name', value: 'avenantChangementPlageDureeIndetermineeNuit', operator: '==', condition: ','},
                    {key: 'name', value: 'avenantChangementPlageDureeDetermineeNuit', operator: '==', condition: ','},
                    {key: 'name', value: 'avenantPlageDureeIndeterminee', operator: '==', condition: ','},
                    {key: 'name', value: 'avenantPlageDureeDeterminee', operator: '==', condition: ','},
                    {key: 'name', value: 'avenantFormateur', operator: '==', condition: ','},
                    {key: 'name', value: 'avenantManager', operator: '==', condition: ','},
                    {key: 'name', value: 'avenantResponsableDeZone', operator: '==', condition: ','},
                ]
            } as RsqlParams).toPromise();
    }

    async getConvocation(): Promise<DataPaginated<ContractType>> {
        return this.contractTypeService.getAll(
            {
                page: 0, pageSize: 100, q: [
                    {key: 'status', value: 'ENABLED', operator: '=='},
                    {key: 'name', value: 'convocationSimple', operator: '==', condition: ','},
                    {key: 'name', value: 'convocationSanction', operator: '==', condition: ','},
                    {key: 'name', value: 'convocationPrealableALicenciement', operator: '==', condition: ','},
                    {key: 'name', value: 'convocationPrealableALicenciementMAP', operator: '==', condition: ','},
                    {key: 'name', value: 'convocationVM', operator: '==', condition: ','},
                    {key: 'name', value: 'convocationDp', operator: '==', condition: ','},
                ]
            } as RsqlParams).toPromise();
    }
    async getFinPeriodeEssai(): Promise<DataPaginated<ContractType>> {
        return this.contractTypeService.getAll(
            {
                page: 0, pageSize: 100, q: [
                    {key: 'status', value: 'ENABLED', operator: '=='},
                    {key: 'name', value: 'finPeriodeEssai', operator: '==', condition: ','},
                    {key: 'name', value: 'finPeriodeEssaiML', operator: '==', condition: ','},
                ]
            } as RsqlParams).toPromise();
    }

    async getAttestation(): Promise<DataPaginated<ContractType>> {
        return this.contractTypeService.getAll(
            {
                page: 0, pageSize: 100, q: [
                    {key: 'status', value: 'ENABLED', operator: '=='},
                    {key: 'name', value: 'attestation', operator: '==', condition: ','},
                    {key: 'name', value: 'attestationManuelle', operator: '==', condition: ','},
                ]
            } as RsqlParams).toPromise();
    }

    async getSanction(): Promise<DataPaginated<ContractType>> {
        return this.contractTypeService.getAll(
            {
                page: 0, pageSize: 100, q: [
                    {key: 'status', value: 'ENABLED', operator: '=='},
                    {key: 'name', value: 'sanctionLicenciement', operator: '==', condition: ','},
                    {key: 'name', value: 'sanctionAvertissement', operator: '==', condition: ','},
                    {key: 'name', value: 'sanctionRappelAOrdre', operator: '==', condition: ','},
                    {key: 'name', value: 'sanctionMiseAPied1Jour', operator: '==', condition: ','},
                    {key: 'name', value: 'sanctionMiseAPied2Jour', operator: '==', condition: ','},
                    {key: 'name', value: 'sanctionMiseAPied3Jour', operator: '==', condition: ','},
                ]
            } as RsqlParams).toPromise();
    }
    async getStc(): Promise<DataPaginated<ContractType>> {
        return this.contractTypeService.getAll(
            {
                page: 0, pageSize: 100, q: [
                    {key: 'status', value: 'ENABLED', operator: '=='},
                    {key: 'name', value: 'stcBulletinPortabiliteMutuelle', operator: '==', condition: ','},
                    {key: 'name', value: 'stcBulletinPortabilitePrevoyance', operator: '==', condition: ','},
                    {key: 'name', value: 'stcNoticePortabiliteMutuelle', operator: '==', condition: ','},
                    {key: 'name', value: 'stcNoticePortabilitePrevoyance', operator: '==', condition: ','},
                    {key: 'name', value: 'stcAttestation', operator: '==', condition: ','},
                ]
            } as RsqlParams).toPromise();
    }

}

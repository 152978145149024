import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input, Output,
    ViewEncapsulation
} from '@angular/core';
import {MatFormFieldModule} from "@angular/material/form-field";
import {TranslocoPipe} from "@ngneat/transloco";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {MatExpansionModule} from "@angular/material/expansion";
import {Address, Doctor, Template} from "../../models";
import {EmployeService} from "../../services/employe.service";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {DisplayAddressComponent} from "../display-address/display-address.component";
import {DisplayPhoneComponent} from "../display-phone/display-phone.component";
import {FileUploadComponent, FileUploaded} from "../file-upload/file-upload.component";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DataCenterServiceService} from "../../services/data-center-service.service";

@Component({
    selector: 'download-manager',
    templateUrl: './download-manager.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatFormFieldModule,
        TranslocoPipe,
        MatIconModule,
        MatSelectModule,
        NgForOf,
        FormsModule,
        MatExpansionModule,
        MatInputModule,
        MatButtonModule,
        NgIf,
        DisplayAddressComponent,
        DisplayPhoneComponent,
        FileUploadComponent
    ]
})
export class DisplayTemplateComponent
{

    url: SafeResourceUrl;

    constructor(
        private ds: DataCenterServiceService,
        private domSanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.ds.downloadSubject.subscribe((_url: any) => {
            if (_url) {
                // this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(_url);
                // window.open(_url, '_blank');
                window.location.href = _url;
            }
        });
    }

}

import { SocketIoConfig } from 'ngx-socket-io';
import {environment} from 'environments/environment';

export class WebSocketConfig implements SocketIoConfig {
  url: string;
  options?: {
    path?: string;
    reconnection?: boolean;
    reconnectionAttempts?: number;
    reconnectionDelay?: number;
    reconnectionDelayMax?: number;
    randomizationFactor?: number;
    timeout?: number;
    autoConnect?: boolean;
    query?: {
      [key: string]: string | null;
    };
    parser?: any;
    upgrade?: boolean;
    forceJSONP?: boolean;
    jsonp?: boolean;
    forceBase64?: boolean;
    enablesXDR?: boolean;
    timestampRequests?: boolean;
    timestampParam?: string;
    policyPort?: number;
    transports?: string[];
    transportOptions?: any;
    rememberUpgrade?: boolean;
    onlyBinaryUpgrades?: boolean;
    requestTimeout?: number;
    protocols?: any;
  };

  constructor() {
    this.url =
      environment.service.webSocket.url === '/' || !environment.service.webSocket.url
        ? window.location.origin
        : environment.service.webSocket.url;
    this.options = {
      path: environment.service.webSocket.path,
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: localStorage.getItem('id_token'),
          },
        },
      },
    };
  }
}

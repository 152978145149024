import {HttpParams} from "@angular/common/http";
import {RsqlParams} from "../models/rsql-params";


export class RsqlParamsMapper {
    constructor(public params: RsqlParams) {}

    getHttpParams(): HttpParams {
        let httpParams = new HttpParams();

        httpParams = httpParams.set('page', this.params.page.toString());
        httpParams = httpParams.set('pageSize', this.params.pageSize.toString());
        if (this.params.sort) {
            httpParams = httpParams.set('sort', this.params.sort);
        }
        httpParams = !this.params.q.length ? httpParams : httpParams.set('q', this.getQueryString());

        return httpParams;
    }

    /**
     * Return q query as formatted string
     */
    getQueryString(): string {
        if (this.params.q.length === 0) {
            return '';
        }
        let query = '';

        this.params.q.forEach((el) => {
            const condition = el.condition || ';';
            if (!el.key) {
                query += `${el.value}${condition}`;
            } else {
                const operator = el.operator || '==';
                query += `${el.key}${operator}${el.value}${condition}`;
            }
        });

        return query.slice(0, -1);
    }
}

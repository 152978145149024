import {Route} from '@angular/router';
import {initialDataResolver} from 'app/app.resolvers';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch: 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')
            },
            {
                path: 'reset-password/:verificationToken/:email',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')
            },
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')
            }
        ]
    },

    // Dashboard routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.routes')},
            {
                path: 'doc/edit',
                loadChildren: () => import('app/modules/dashboard/add-doc/add-doc.routes'),
            },
            {path: 'doc/edit/:employeId/:contractTypeId/:docId', loadChildren: () => import('app/modules/doc/doc.routes')},
            {path: 'doc/list/:contractTypeName', loadChildren: () => import('app/modules/doc/list/list.routes')},
            {path: 'employe', loadChildren: () => import('app/modules/employe/employe.routes')},
            {path: 'employe/edit/:employeId', loadChildren: () => import('app/modules/employe/edit-employe/edit-employe.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {
                path: 'admin',
                children: [
                    // Admin
                    { path: 'company', loadChildren: () => import('app/modules/admin/company/company.routes')},
                    { path: 'company/edit/:companyId', loadChildren: () => import('app/modules/admin/company/edit-admin-company/edit-admin-company.routes')},

                    { path: 'contract-type', loadChildren: () => import('app/modules/admin/contract-type/contract-type.routes')},
                    { path: 'contract-type/edit/:contractTypeId', loadChildren: () => import('app/modules/admin/contract-type/edit-admin-contract-type/edit-admin-contract-type.routes')},

                    { path: 'restaurant', loadChildren: () => import('app/modules/admin/restaurant/restaurant.routes')},
                    { path: 'restaurant/edit/:restaurantId', loadChildren: () => import('app/modules/admin/restaurant/edit-admin-restaurant/edit-admin-restaurant.routes')},

                    { path: 'user', loadChildren: () => import('app/modules/admin/user/user.routes')},
                    { path: 'user/edit/:userId', loadChildren: () => import('app/modules/admin/user/edit-admin-user/edit-admin-user.routes')},

                ]
            },

        ]
    },
];
